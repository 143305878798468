import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '@env/environment';
import { EscalationHistoryDto } from '@shared/classes';
import { BaseRequestControllerService, DataService } from '@shared/services';

@Injectable({
  providedIn: 'root',
})
export class EscalationHistoryDataService extends BaseRequestControllerService<EscalationHistoryDto> {
  constructor(
    private dataService: DataService,
    private router: Router
  ) {
    super(
      dataService,
      environment.issue,
      '/escalation-histories',
      [
        'id',
        'code',
        'tags',
        'tagsCodes',
        'lockedBy',
        'lockedForEdit',
        'lockedUntil',
        'userAccessLevel',
        'recordStatus',
        'translations',
        'originalLanguage',
        'creatorName',
        'lastModifierName',
        'creationDate',
        'lastModificationDate',
        'name',
        'description',
        'assignee',
        'escalation',
        'escalationDate',
      ],
      null
    ); //@todo add module
  }
  navigateToListPage() {
    this.router.navigateByUrl(`issue-management/issue-type/list`);
  }
}
